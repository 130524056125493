import React from "react";
import check from "./icons/check.svg";

const Thanks = ({ ...props }) => (
  <main className="main--thanks-page">
    <section className="thanks-container">
      <div className="content">
        <div className="circle-icon-container circle-icon-container--thanks">
          <img src={check} alt="thanks" />
        </div>
        <h1>Yay! Thanks for joining.</h1>
        <p>I'll be in touch soon with info on downloading the app.</p>
        <br />
        <p>
          Meanwhile, I encourage you to think about the family, friends,
          colleagues, and mentors you'd like to have in your life more often,
          but just haven't had the time or energy to keep in touch with.{" "}
          <strong>This is your queue</strong>.
        </p>
        <br />
        <p>I can't wait to hear what you think.</p>
        <br />
        <p>Jeremy</p>
        <p>Design + development @ Queue</p>
      </div>
    </section>
  </main>
);

export default Thanks;
