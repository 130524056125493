import React, { Component } from "react";
import Apple from "./icons/Apple";

const AppStoreButton = ({ ...props }) => (
  <a
    href="https://apps.apple.com/us/app/queue-personal-crm/id1451429218?itsct=apps_box&amp;itscg=30200"
    className="button"
    {...props}
  >
    <Apple />
    <span>Download on the App Store</span>
  </a>
  // <a
  //   href="https://apps.apple.com/us/app/queue-personal-crm/id1451429218?itsct=apps_box&amp;itscg=30200"
  //   style={{
  //     display: "inline-block",
  //     overflow: "hidden",
  //     borderTopLeftRadius: "13px",
  //     borderTopRightRadius: "13px",
  //     borderBottomRightRadius: "13px",
  //     borderBottomLeftRadius: "13px",
  //     width: "250px",
  //     height: "83px",
  //   }}
  // >
  //   <img
  //     src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/white/en-US?size=250x83&amp;releaseDate=1604534400&h=2865dfca291f154c78bddd508db8d6c9"
  //     alt="Download on the App Store"
  //     style={{
  //       borderTopLeftRadius: "13px",
  //       borderTopRightRadius: "13px",
  //       borderBottomRightRadius: "13px",
  //       borderBottomLeftRadius: "13px",
  //       width: "250px",
  //       height: "83px",
  //     }}
  //   />
  // </a>
);

export default AppStoreButton;
