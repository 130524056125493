import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import PrivacyPolicy from "./PrivacyPolicy";
import Terms from "./Terms";
import Home from "./Home";
import Thanks from "./Thanks";
import "./App.css";

const App = ({ ...props }) => {
  return (
    <div className="app-container">
      <Router>
        <ScrollToTop />
        <Header />

        <Route path="/" exact component={Home} />
        <Route path="/signup-confirmation" exact component={Thanks} />
        <Route path="/privacy-policy" exact component={PrivacyPolicy} />
        <Route path="/terms" exact component={Terms} />

        <Footer />
      </Router>
    </div>
  );
};

const Header = ({ ...props }) => {
  let { pathname } = useLocation();
  console.log(pathname);
  if (pathname === "/") {
    return null;
  } else {
    return (
      <header>
        <Link to="/">Queue</Link>
      </header>
    );
  }
};

const Footer = ({ ...props }) => (
  <footer>
    <a href="https://jeremylubin.com">&copy; {getCurrentYear()} Lubin Labs</a> ∙{" "}
    <Link to="/privacy-policy">Privacy Policy</Link> ∙{" "}
    <Link to="/terms">Terms of Service</Link>
  </footer>
);

const getCurrentYear = () => {
  const date = new Date();
  return date.getFullYear();
};

export default App;
