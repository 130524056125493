import React from "react";

const Apple = ({ ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.06187 7.0395C10.388 6.98276 11.2529 7.72043 12.1177 7.72043C12.9826 7.72043 13.7898 6.98276 15.1736 7.0395C16.3844 7.0395 17.7105 7.49345 18.633 8.57158C17.3069 9.19576 16.3267 10.5576 16.3267 12.1464C16.3267 13.8488 17.4799 15.2673 18.979 15.778C18.3447 17.5938 17.3069 19.2962 15.8655 20.2041C14.0204 21.1687 12.9249 19.4664 12.1177 19.4664C11.2529 19.4664 10.215 21.1687 8.36998 20.2041C6.17899 18.8422 4.85286 15.7213 4.56457 13.1111C4.04566 8.62833 6.75556 7.0395 9.06187 7.0395ZM11.8871 6.75578C11.8294 4.08883 13.2709 2.84046 15.7501 2.5C15.2889 5.39393 14.1357 6.30183 11.8871 6.75578Z"
      fill={props.color ? props.color : "#F25940"}
    />
  </svg>
);

export default Apple;
