import React from "react";
import SignupForm from "./SignupForm";
import AppStoreButton from "./AppStoreButton";
import lock from "./icons/lock.svg";
import googleCalendar from "./images/google-calendar.png";
import gmail from "./images/gmail.png";
import apple from "./images/apple.png";
import twitter from "./images/twitter.png";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import kousha from "./images/kousha.jpg";
import rohini from "./images/rohini.png";
import craig from "./images/craig.jpg";
import steph from "./images/steph.jpg";

const Home = ({ ...props }) => (
  <main className="main--index-page">
    <div className="header-area">
      <section>
        <div className="content">
          <h1>
            Hi there.
            <br />
            <br />
            I'm terrible at keeping in touch with the people I care about, and
            it makes me sad.
            <br />
            <br />
            If you're bad at this too, you might like Queue!
            <br />
            <br />
            Jeremy
          </h1>
          <p>Maker of Queue. It's an app.</p>
        </div>
      </section>
      <MiniDivider />
      <section>
        <div className="content">
          <h1>Want to try it?</h1>
          <p>Queue is free for the first month!</p>
          <AppStoreButton
            style={{
              marginTop: "16px",
            }}
          />
        </div>
      </section>
      <MiniDivider />
      <section id="scroll-section">
        <div className="content">
          <h1>Want to know more?</h1>
          <p>
            Scroll down for the basics! I've kept this short and sweet because
            making a fancy website takes time away from making my relationships
            happy and healthy.
          </p>
        </div>
      </section>
    </div>
    <section>
      <div className="content">
        <div className="step-number">1</div>
        <h1>
          It all starts with the people you’d like to have in your life more
          often.
        </h1>
        <div className="step-graphic">
          <div className="avatars">
            <Avatar
              image={craig}
              value={0}
              iconAlt="iPhone Contacts"
              icon={apple}
              offset={0}
            />
            <Avatar
              image={kousha}
              value={0}
              iconAlt="Twitter"
              icon={twitter}
              offset={-20}
            />
            <Avatar
              image={rohini}
              value={0}
              icon={gmail}
              iconAlt="Gmail"
              offset={16}
            />
            <Avatar
              image={steph}
              value={0}
              icon={googleCalendar}
              iconAlt="Google Calendar"
              offset={-6}
            />
          </div>
        </div>
        <p>
          Queue helps you keep in touch with people on a regular basis, and it's
          really easy to import them from your Google Calendar meetings, Gmail
          threads, Twitter following (coming soon), and iPhone contacts. These
          are some of the real friends, family, and former colleagues I tend to
          neglect!
        </p>
      </div>
    </section>
    <MiniDivider />
    <section>
      <div className="content">
        <div className="step-number">2</div>
        <h1>
          Queue keeps track of how long it's been since you last caught up.
        </h1>
        <div className="step-graphic">
          <div className="avatars">
            <Avatar image={craig} value={60} offset={0} key="craig-2" />
            <Avatar image={steph} value={30} offset={0} key="steph-2" />
            <Avatar image={kousha} value={10} offset={0} key="miles-2" />
            <Avatar image={rohini} value={100} offset={0} key="rohini-2" />
          </div>
        </div>
        <p>
          Your queue is always sorted by who’s next for a catch up, and people
          are automatically sent down the queue when we see a catch up on your
          Google Calendar or you record one manually in the app.
        </p>
      </div>
    </section>
    <MiniDivider />
    <section>
      <div className="content">
        <div className="step-number">3</div>
        <h1>You’ll get reminders to reach out and prompts to reflect.</h1>
        <div className="step-graphic">
          <div className="notifications">
            <div className="notification">
              <Avatar image={rohini} value={100} offset={0} size="small" />
              <div className="notification-text">
                <span className="title">Time to catch up with Rohini!</span>
                <span className="body">Tap to get in touch</span>
              </div>
            </div>
            <div className="notification">
              <Avatar image={kousha} value={99} offset={0} size="small" />
              <div className="notification-text">
                <span className="title">
                  How was your catch up with Kousha?
                </span>
                <span className="body">Tap to record some notes</span>
              </div>
            </div>
          </div>
        </div>
        <p>
          I work really hard to make sure these are helpful and not annoying. If
          they aren't right for you, let me know! It’s just software. I can
          change it.
        </p>
      </div>
    </section>
    <section id="privacy-section">
      <div className="content">
        <div className="step-number">
          <img src={lock} alt="lock" />
        </div>
        <h1>A note about privacy.</h1>
        <p>
          Your contact and catch up data is sensitive and private, and I’ll
          never sell it or show you an ad, ever. Instead, I charge money for
          Queue so that I can pay my rent with a clean conscience. If you’ve got
          questions, email me any time at{" "}
          <a href="mailto:jeremy@queue.community">jeremy@queue.community</a>.
        </p>
      </div>
    </section>
    <section id="bottom-signup-section" className="gray">
      <div className="content">
        <h1>Give it a try!</h1>
        <p>It’s just an app. You can always delete it.</p>
        <AppStoreButton
          style={{
            marginTop: "16px",
          }}
        />
      </div>
    </section>
  </main>
);

const MiniDivider = ({ ...props }) => (
  <div className="mini-divider">
    <div></div>
  </div>
);

const Avatar = ({ ...props }) => (
  <div
    className="avatar"
    style={{
      width: props.size === "small" ? 64 : 96,
      height: props.size === "small" ? 64 : 96,
      borderRadius: props.size === "small" ? 32 : 48,
      marginTop: `${props.offset}px`,
      background: "#ffffff",
    }}
  >
    <CircularProgressbarWithChildren
      key={props.key}
      value={props.value}
      width={props.size === "small" ? 64 : 96}
      strokeWidth={3}
      background={true}
      //   circleRatio={3}
      styles={buildStyles({
        trailColor: "#EFF1F3",
        pathColor: `${props.value >= 100 ? "#CE004A" : "#f25940"}`,
        backgroundColor: "#ffffff",
      })}
    >
      <div
        className="avatar-image"
        style={{
          height: props.size === "small" ? 56 : 86,
          width: props.size === "small" ? 56 : 86,
          borderRadius: props.size === "small" ? 28 : 43,
          backgroundImage: `url(${props.image})`,
          backgroundSize: "cover",
          overflow: "hidden",
        }}
      />
      {props.icon && (
        <img
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            height: "32px",
            width: "32px",
            //   boxShadow: "0px 4px 4px rgba(61, 84, 103, 0.08)",
            background: "transparent",
          }}
          src={props.icon}
          alt={props.iconAlt}
        />
      )}
    </CircularProgressbarWithChildren>
  </div>
);

export default Home;
