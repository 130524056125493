import React from "react";

const PrivacyPolicy = ({ ...props }) => (
  <main className="main--privacy-policy-page">
    <section>
      <div className="content">
        <h1>Privacy Policy</h1>
        <p>This Privacy Policy was last updated on January 6, 2020.</p>
        <br />
        <h3>Our Policy</h3>
        <br />
        <p>
          Welcome to the web site (the “<strong>Site</strong>”) of Lubin Labs,
          Inc. (“<strong>Lubin Labs</strong>”, “we”, “us” and/or “our”). This
          Site is operated by Lubin Labs and has been created to provide
          information about our company and our personal relationship management
          services, mobile applications and related services (together with the
          Site, the “<strong>Services</strong>”) to our Service visitors (“you”,
          “your”). This Privacy Policy sets forth Lubin Labs’s policy with
          respect to information including personally identifiable data (“
          <strong>Personal Data</strong>”) and other information that is
          collected from visitors to the Site and Services.
        </p>
        <br />
        <h3>Information We Collect</h3>
        <br />
        <p>
          When you interact with us through the Services, we may collect
          Personal Data and other information from you, as further described
          below:
        </p>
        <br />
        <p>
          <strong>Personal Data That You Provide Through the Services</strong>:
          We collect Personal Data from you when you voluntarily provide such
          information, such as when you contact us with inquiries, respond to
          one of our surveys, register for access to the Services or use certain
          Services. Wherever Lubin Labs collects Personal Data we make an effort
          to provide a link to this Privacy Policy.{" "}
        </p>
        <br />
        <p>
          <strong>
            By voluntarily providing us with Personal Data, you are consenting
            to our use of it in accordance with this Privacy Policy. If you
            provide Personal Data to the Services, you acknowledge and agree
            that such Personal Data may be transferred from your current
            location to the offices and servers of Lubin Labs and the authorized
            third parties referred to herein located in the United States.{" "}
          </strong>
        </p>
        <br />
        <p>
          <strong>Other information</strong>
        </p>
        <br />
        <p>
          <strong>Non-Identifiable Data</strong>: When you interact with Lubin
          Labs through the Services, we receive and store certain personally
          non-identifiable information. Such information, which is collected
          passively using various technologies, cannot presently be used to
          specifically identify you. Lubin Labs may store such information
          itself or such information may be included in databases owned and
          maintained by Lubin Labs affiliates, agents or service providers. The
          Services may use such information and pool it with other information
          to track, for example, the total number of visitors to our Site, the
          number of visitors to each page of our Site, and the domain names of
          our visitors' Internet service providers. It is important to note that
          no Personal Data is available or used in this process.{" "}
        </p>
        <br />
        <p>
          In operating the Services, we may use a technology called "cookies." A
          cookie is a piece of information that the computer that hosts our
          Services gives to your browser when you access the Services. Our
          cookies help provide additional functionality to the Services and help
          us analyze Services usage more accurately. For instance, our Site may
          set a cookie on your browser that allows you to access the Services
          without needing to remember and then enter a password more than once
          during a visit to the Site. In all cases in which we use cookies, we
          will not collect Personal Data except with your permission. On most
          web browsers, you will find a “help” section on the toolbar. Please
          refer to this section for information on how to receive notification
          when you are receiving a new cookie and how to turn cookies off. We
          recommend that you leave cookies turned on because they allow you to
          take advantage of some of the Service features.
        </p>
        <br />
        <p>
          <strong>Aggregated Personal Data</strong>: In an ongoing effort to
          better understand and serve the users of the Services, Lubin Labs
          often conducts research on its customer demographics, interests and
          behavior based on the Personal Data and other information provided to
          us. This research may be compiled and analyzed on an aggregate basis,
          and Lubin Labs may share this aggregate data with its affiliates,
          agents and business partners. This aggregate information does not
          identify you personally. Lubin Labs may also disclose aggregated user
          statistics in order to describe our services to current and
          prospective business partners, and to other third parties for other
          lawful purposes.
        </p>
        <br />
        <p>
          <strong>Location Information</strong>: Our Service may collect and use
          your location information (for example, by using the GPS on your
          mobile device) to provide certain functionality of our Service. We may
          also use your location information in an aggregate way, as described
          above in the “Aggregated Personal Data” section.
        </p>
        <br />
        <p>
          <strong>Analytics</strong>: We may allow third party service providers
          to use cookies or similar technologies to collect information about
          your browsing activities over time and across different websites
          following your use of the Services. For example, we use Google
          Analytics, a web analytics service provided by Google, Inc.
          (“Google”). Google Analytics uses cookies to help us analyze how users
          use the Site and enhance your experience when you use the Service. For
          more information on how Google uses this data, go to{" "}
          <a
            href="http://www.google.com/policies/privacy/partners/"
            target="_blank"
          >
            www.google.com/policies/privacy/partners/
          </a>
          .{" "}
        </p>
        <br />
        <h3>Our Use of Your Personal Data and Other Information</h3>
        <br />
        <p>
          Lubin Labs uses the Personal Data you provide in a manner that is
          consistent with this Privacy Policy. If you provide Personal Data for
          a certain reason, we may use the Personal Data in connection with the
          reason for which it was provided. For instance, if you contact us by
          email, we will use the Personal Data you provide to answer your
          question or resolve your problem. Also, if you provide Personal Data
          in order to obtain access to the Services, we will use your Personal
          Data to provide you with access to such services and to monitor your
          use of such services. Lubin Labs and its subsidiaries and affiliates
          (the “Related Companies”) may also use your Personal Data and other
          personally non-identifiable information collected through the Services
          to help us improve the content and functionality of the Services, to
          better understand our users and to improve the Services. Lubin Labs
          and its affiliates may use this information to contact you in the
          future to tell you about services we believe will be of interest to
          you. If we do so, each marketing communication we send you will
          contain instructions permitting you to "opt-out" of receiving future
          marketing communications. In addition, if at any time you wish not to
          receive any future marketing communications or you wish to have your
          name deleted from our mailing lists, please contact us as indicated
          below.
        </p>
        <br />
        <p>
          If Lubin Labs intends on using any Personal Data in any manner that is
          not consistent with this Privacy Policy, you will be informed of such
          anticipated use prior to or at the time at which the Personal Data is
          collected.
        </p>
        <br />
        <h3>Our Disclosure of Your Personal Data and Other Information</h3>
        <br />
        <p>
          Lubin Labs is not in the business of selling your information. We
          consider this information to be a vital part of our relationship with
          you. There are, however, certain circumstances in which we may share
          your Personal Data with certain third parties without further notice
          to you, as set forth below:{" "}
        </p>
        <br />
        <p>
          <strong>Business Transfers</strong>: As we develop our business, we
          might sell or buy businesses or assets. In the event of a corporate
          sale, merger, reorganization, dissolution or similar event, Personal
          Data may be part of the transferred assets.{" "}
        </p>
        <br />
        <p>
          <strong>Related Companies</strong>: We may also share your Personal
          Data with our Related Companies for purposes consistent with this
          Privacy Policy.
        </p>
        <br />
        <p>
          <strong>Agents, Consultants and Related Third Parties</strong>: Lubin
          Labs, like many businesses, sometimes hires other companies to perform
          certain business-related functions. Examples of such functions include
          mailing information, maintaining databases and processing payments.
          When we employ another entity to perform a function of this nature, we
          only provide them with the information that they need to perform their
          specific function.
        </p>
        <br />
        <p>
          <strong>Legal Requirements</strong>: Lubin Labs may disclose your
          Personal Data if required to do so by law or in the good faith belief
          that such action is necessary to (i) comply with a legal obligation,
          (ii) protect and defend the rights or property of Lubin Labs, (iii)
          act in urgent circumstances to protect the personal safety of users of
          the Services or the public, or (iv) protect against legal liability.
        </p>
        <br />
        <h3>Your Choices</h3>
        <br />
        <p>
          You can visit the Site without providing any Personal Data. If you
          choose not to provide any Personal Data, you may not be able to use
          certain Services.{" "}
        </p>
        <br />
        <h3>Exclusions</h3>
        <br />
        <p>
          This Privacy Policy does not apply to any Personal Data collected by
          Lubin Labs other than Personal Data collected through the Services.
          This Privacy Policy shall not apply to any unsolicited information you
          provide to Lubin Labs through the Services or through any other means.
          This includes, but is not limited to, information posted to any public
          areas of the Services, such as forums, any ideas for new products or
          modifications to existing products, and other unsolicited submissions
          (collectively, “Unsolicited Information”). All Unsolicited Information
          shall be deemed to be non-confidential and Lubin Labs shall be free to
          reproduce, use, disclose, and distribute such Unsolicited Information
          to others without limitation or attribution.
        </p>
        <br />
        <h3>Children</h3>
        <br />
        <p>
          Lubin Labs does not knowingly collect Personal Data from children
          under the age of 13. If you are under the age of 13, please do not
          submit any Personal Data through the Services. We encourage parents
          and legal guardians to monitor their children’s Internet usage and to
          help enforce our Privacy Policy by instructing their children never to
          provide Personal Data on the Services without their permission. If you
          have reason to believe that a child under the age of 13 has provided
          Personal Data to Lubin Labs through the Services, please contact us,
          and we will endeavor to delete that information from our databases.
        </p>
        <br />
        <h3>Links to Other Web Sites</h3>
        <br />
        <p>
          This Privacy Policy applies only to the Services. The Services may
          contain links to other web sites not operated or controlled by Lubin
          Labs (the “Third Party Sites”). The policies and procedures we
          described here do not apply to the Third Party Sites. The links from
          the Services do not imply that Lubin Labs endorses or has reviewed the
          Third Party Sites. We suggest contacting those sites directly for
          information on their privacy policies.
        </p>
        <br />
        <h3>Integrating Social Networking Services</h3>
        <br />
        <p>
          One of the special features of the Service is that it allows you to
          enable or log in to the Services via various social networking
          services like Google, Facebook or Twitter (“Social Networking
          Service(s)”). By directly integrating these services, we make your
          online experiences richer and more personalized. To take advantage of
          this feature, we will ask you to log into or grant us permission via
          the relevant Social Networking Service. When you add a Social
          Networking Services account to the Service or log into the Service
          using your Social Networking Services account, we will collect
          relevant information necessary to enable the Service to access that
          Social Networking Service and your data contained within that Social
          Networking Service. As part of such integration, the Social Networking
          Service will provide us with access to certain information that you
          have provided to the Social Networking Service, and we will use, store
          and disclose such information in accordance with this Privacy Policy.
          However, please remember that the manner in which Social Networking
          Services use, store and disclose your information is governed by the
          policies of such third parties, and Lubin Labs shall have no liability
          or responsibility for the privacy practices or other actions of any
          Social Networking Services that may be enabled within the Service.{" "}
        </p>
        <br />
        <p>
          You may also have the option of posting your Services activities to
          Social Networking Services when you access content through the
          Services (for example, you may post to Facebook that you performed an
          activity on the Service); you acknowledge that if you choose to use
          this feature, your friends, followers and subscribers on any Social
          Networking Services you have enabled will be able to view such
          activity.
        </p>
        <br />
        <h3>Security</h3>
        <br />
        <p>
          Lubin Labs takes reasonable steps to protect the Personal Data
          provided via the Services from loss, misuse, and unauthorized access,
          disclosure, alteration, or destruction. However, no Internet or email
          transmission is ever fully secure or error free. In particular, email
          sent to or from the Services may not be secure. Therefore, you should
          take special care in deciding what information you send to us via
          email. Please keep this in mind when disclosing any Personal Data to
          Lubin Labs via the Internet.{" "}
        </p>
        <br />
        <h3>Changes to Lubin Labs’s Privacy Policy</h3>
        <br />
        <p>
          The Services and our business may change from time to time. As a
          result, at times it may be necessary for Lubin Labs to make changes to
          this Privacy Policy. Lubin Labs reserves the right to update or modify
          this Privacy Policy at any time and from time to time without prior
          notice. Please review this policy periodically, and especially before
          you provide any Personal Data. This Privacy Policy was last updated on
          the date indicated above. Your continued use of the Services after any
          changes or revisions to this Privacy Policy shall indicate your
          agreement with the terms of such revised Privacy Policy.
        </p>
        <br />
        <h3>Access to Information; Contacting Lubin Labs</h3>
        <br />
        <p>
          To keep your Personal Data accurate, current, and complete, please
          contact us as specified below. We will take reasonable steps to update
          or correct Personal Data in our possession that you have previously
          submitted via the Services.{" "}
        </p>
        <br />
        <p>
          Please also feel free to contact us if you have any questions about
          Lubin Labs’s Privacy Policy or the information practices of the
          Services.
        </p>
        <br />
        <p>
          You may contact us as follows:{" "}
          <a href="mailto:support@lubinlabs.com">support@lubinlabs.com</a>
        </p>
      </div>
    </section>
  </main>
);

export default PrivacyPolicy;
